import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1A3542',
        success: '#00A387',
        info: '#4892B8',
        warning: '#FA6616',
        error: '#B00718',
        accent: '#379C6F',
        greyApp: "#212121"
      },
    },
  },
});
