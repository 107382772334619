<template>
  <v-snackbar v-model="showing" color="primary lighten-1" :timeout="-1">
    <div class="d-flex justify-space-between" :class="{
      'align-center': $vuetify.breakpoint.smAndUp,
      'flex-column': $vuetify.breakpoint.xs,
    }">
      <span class="font-weight-medium">
        Utilizamos Cookies de terceiros (Google e Facebook) para melhorar a experiência em nosso site.
      </span>
      <div class="d-flex justify-end align-center">
        <v-btn small text @click="showing = false">
          Recusar
        </v-btn>
        <v-btn color="success" small @click="acceptCookies()">
          Aceitar
        </v-btn>
      </div>
    </div>
  </v-snackbar>  
</template>

<script>
const STORAGE_KEY = "cookiesAccepted";

export default {
  name: "CookiesWarning",

  data: () => ({
    showing: false,
    useCookies: window.location.hostname == "econsenso.com.br",
  }),

  methods: {
    acceptCookies() {
      if (this.useCookies){
        localStorage.setItem(STORAGE_KEY, 'accepted')
      }
      this.showing = false
      this.injectCookiesCode()
    },
    injectCookiesCode(){
      if (this.useCookies){
        let analytics = document.createElement('script')
        analytics.src = "https://www.googletagmanager.com/gtag/js?id=G-XKX2CXCR4J"
        analytics.onload = () => {
          window.dataLayer = window.dataLayer || [];
          window.gtag = function(){
            dataLayer.push(arguments);
          }
          window.gtag('js', new Date());
          window.gtag('config', 'G-XKX2CXCR4J');
        }
        document.body.appendChild(analytics)

        // Facebook Pixel Code
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(
          window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'
        );
        fbq('init', '1173162076441098');
        fbq('track', 'PageView');
      }
    },
  },

  watch: {
    '$store.state.logged'(logged){
      if (logged){
        this.injectCookiesCode()
      }
    },
  },

  mounted() {
    if (this.$store.state.logged){
      this.injectCookiesCode()
    } else if (localStorage.getItem(STORAGE_KEY) == 'accepted'){
      this.injectCookiesCode()
    } else {
      this.showing = true
    }
  },
}
</script>
