import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'landing',
    component: () => import('@/views/Home.vue')
  },
  {
    path: '/lancamento',
    name: 'landing-launch',
    component: () => import('@/views/landing-pages/Launch.vue')
  },
  {
    path: '/ebook',
    name: 'landing-ebook',
    component: () => import('@/views/landing-pages/Ebook.vue')
  },
  {
    path: '/botao-ebook',
    redirect: { name: 'landing' },
  },
  {
    path: '/saiba-mais',
    name: 'landing-more-info',
    component: () => import('@/views/landing-pages/MoreInfo.vue')
  },
  {
    path: '/newsletter',
    name: 'landing-newsletter',
    component: () => import('@/views/landing-pages/Newsletter.vue')
  },
  {
    path: '/teste-gratuito',
    name: 'landing-free-trial',
    component: () => import('@/views/landing-pages/FreeTrial.vue')
  },
  {
    path: '/aplicativo',
    name: 'landing-install-app',
    component: () => import('@/views/landing-pages/InstallApp.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/cadastro',
    name: 'register',
    component: () => import('@/views/Register.vue')
  },
  {
    path: '/recuperar-senha/:code',
    name: 'recover-password',
    component: () => import('@/views/RecoverPassword.vue')
  },
  {
    path: '/confirmacao-email/:code',
    name: 'confirm-email',
    component: () => import('@/views/ConfirmEmail.vue')
  },
  {
    path: '/confirmacao-telefone/:code',
    name: 'confirm-phone',
    component: () => import('@/views/ConfirmPhone.vue')
  },
  {
    path: '/app/',
    component: () => import('@/views/app/Main.vue'),
    children: [
      {
        path: '',
        name: 'app',
        redirect: { name: 'consents' },
      },
      {
        path: 'contatos',
        name: 'contacts',
        component: () => import('@/views/app/Contacts.vue')
      },
      {
        path: 'novo-consentimento',
        name: 'new-consent',
        component: () => import('@/views/app/NewConsent.vue')
      },
      {
        path: 'consentimentos',
        name: 'consents',
        component: () => import('@/views/app/Consents.vue')
      },
      {
        path: 'controladores',
        name: 'controllers',
        component: () => import('@/views/app/Controllers.vue')
      },
      {
        path: 'dados-utilizados',
        name: 'bearer-info',
        component: () => import('@/views/app/BearerInfo.vue')
      },
      {
        path: 'consequencias',
        name: 'consequences',
        component: () => import('@/views/app/Consequences.vue')
      },
      {
        path: 'riscos',
        name: 'risks',
        component: () => import('@/views/app/Risks.vue')
      },
      {
        path: 'forma',
        name: 'means',
        component: () => import('@/views/app/Means.vue')
      },
      {
        path: 'duracao',
        name: 'duration',
        component: () => import('@/views/app/Duration.vue')
      },

      {
        path: 'responsabilidades',
        name: 'responsabilities',
        component: () => import('@/views/app/Responsabilities.vue')
      },
      {
        path: 'configuracoes',
        name: 'settings',
        component: () => import('@/views/app/Settings.vue')
      },
      {
        path: 'modelos',
        name: 'templates',
        component: () => import('@/views/app/Templates.vue')
      },
      {
        path: 'usuarios',
        name: 'users',
        component: () => import('@/views/app/Users.vue')
      },
      {
        path: 'consentimento/:consent_id',
        name: 'consent',
        component: () => import('@/views/app/Consent.vue')
      },
    ],
  },
  /* {
    path: '/nova-senha',
    name: 'new-password',
    component: () => import('@/views/ForgotPassword.vue')
  }, */
  {
    path: '/responder-consentimento/:public_code',
    name: 'public-link-response',
    component: () => import('@/views/PublicLinkResponse.vue')
  },
  {
    path: '/definir-senha',
    name: 'define-password',
    component: () => import('@/views/DefinePassword.vue')
  },
  {
    path: '/completar-cadastro/:email',
    name: 'complete-register',
    component: () => import('@/views/CompleteRegister.vue')
  },
  {
    path: '/configurar-usuario/:code',
    name: 'configure-user',
    component: () => import('@/views/ConfigureUser.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
