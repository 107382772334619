let dateFormatter = new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
});

let datetimeFormatter = new Intl.DateTimeFormat('pt-BR', {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
});

export default {
  formatMoney(value){
      if (!value){
          return value;
      }
      let [num, decimal] = value.toFixed(2).split('.');
      let negative = false;
      if (num[0] == '-'){
          num = num.slice(1);
          negative = true;
      }
      let newNum = "";
      while (num.length > 3){
          newNum = '.' + num.slice(-3) + newNum;
          num = num.slice(0, -3);
      }
      return `${negative ? '-':''}${num}${newNum},${decimal}`;
  },
  formatDate(str){
    let d = new Date(str)
    return dateFormatter.format(d)
  },
  formatDatetime(str){
    let d = new Date(str)
    return datetimeFormatter.format(d)
  },
}
