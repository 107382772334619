<template>
  <div class="d-flex flex-column fill-height">
    <v-sheet outlined style="position: absolute; width: 100%" align="center" v-if="logo">
      <v-img
        width="100%"
        :height="`${logoHeight}px`"
        contain
        class="text-center text-h5 d-flex align-center justify-center mt-2"
        src="/eConsenso.png"
      />

    </v-sheet>
    <div
      class="flex-grow-1"
      :style="{
        'overflow-y': 'auto',
        'margin-top': `${logo ? logoHeight+8: 0}px`,
      }"
    >
      <v-list class="py-0">
        <template v-for="(item, i) in menu">
          <div :key="i">

            <template v-if="item === undefined">
              <v-divider />
            </template>

            <template v-else-if="!Array.isArray(item)">
              <v-list-item
                :class="item.class"
                exact link :to="{ name: item.route }"
              >
                <v-list-item-icon class="mr-2 px-0">
                  <v-icon :class="item.textClass">{{ item.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title class="text-body-2" :class="item.textClass">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </template>

            <template v-else>
              <v-list-group>
                <template #activator>
                  <v-list-item-content>
                    <v-list-item-title class="text-overline">
                      Cadastros
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
                
                <v-list-item
                  v-for="(item2, j) in item" :key="j"
                  color="primary" dense
                  exact link :to="{ name: item2.route }"
                >
                  <v-list-item-icon class="mr-2 px-0">
                    <v-icon>{{ item2.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="text-body-2">
                    {{ item2.text }}
                  </v-list-item-title>
                </v-list-item>

              </v-list-group>
            </template>

          </div>
        </template>

      </v-list>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",

  props: {
    logo: {type: Boolean, default: false},
  },

  data: () => ({
    logoHeight: 80,
    menu: [
      {
        text: "Novo Consentimento",
        icon: "mdi-newspaper-variant-outline",
        route: "new-consent",
        class: "success lighten-1 rounded elevation-1 ma-1",
        textClass: "white-- text font-weight-medium",
      },
      undefined,
      {
        text: "Modelos",
        icon: "mdi-text-box-plus-outline",
        route: "templates",
      },
      {
        text: "Consentimentos",
        icon: "mdi-view-list",
        route: "consents"
      },
      {
        text: "Titulares",
        icon: "mdi-contacts-outline",
        route: "contacts",
      },
      undefined,
      [ // submenu
        {
          text: "Dados de Titular",
          icon: "mdi-database-lock-outline",
          route: "bearer-info",
        },
        {
          text: "Controladores",
          icon: "mdi-account-group-outline",
          route: "controllers",
        },
        {
          text: "Responsabilidades",
          icon: "mdi-shield-account-outline",
          route: "responsabilities",
        },
        {
          text: "Forma",
          icon: "mdi-briefcase-outline",
          route: "means",
        },
        {
          text: "Duração",
          icon: "mdi-clock-outline",
          route: "duration",
        },
        {
          text: "Riscos",
          icon: "mdi-comment-alert-outline",
          route: "risks",
        },
        {
          text: "Consequências",
          icon: "mdi-alarm-light-outline",
          route: "consequences",
        },
      ]
    ],
  }),
  
};
</script>
