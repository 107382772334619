
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import VueTheMask from 'vue-the-mask'
import rules from '@/rules'
import utils from '@/utils'


Vue.use(VueTheMask)
Vue.config.productionTip = false

Vue.prototype.$axios = require('axios').create({
  baseURL: process.env.NODE_ENV === "production" ? "https://api.econsenso.com.br/" : "http://localhost:8000/",
  withCredentials: true,
});


Vue.prototype.$rules = rules
Vue.prototype.$utils = utils

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
