<template>
  <v-app>
    <v-app-bar
      v-if="!($route.name || '').includes('landing')"
      app
      color="primary"
      elevate-on-scroll
      elevation="4"
    >
      <v-app-bar-nav-icon
        @click="menu = true"
        color="white"
        v-if="$store.state.logged && $vuetify.breakpoint.mdAndDown"
      />

      <div class="d-flex align-center" style="width: 100%">
        <div>
          <v-img
            height="45px"
            width="165px"
            src="/logo-half-white.svg"
            eager
            contain
            position="center left"
          />
        </div>
        <template v-if="$store.state.logged">
          <div class="d-flex flex-column white--text flex-grow-1 text-right">
            <div class="text-body-2 font-weight-bold">
              {{ user.name }}
            </div>
            <div class="text-caption mt-n1">
              <template v-if="user.is_admin">
                Administrador
              </template>
              <template v-else>
                {{ ((user || {}).account_user || {}).name }}
              </template>
            </div>
          </div>
          <v-divider class="grey ligthen-3 mx-2 my-1" vertical />
          <v-menu
            v-if="user.have_accounts"
            tile offset-y left min-width="300px"
            origin="top right" open-delay=""
            transition="scale-transition" class="rounded-b-xl"
          >
            <OtherAccounts ref="otherAccounts" />
            <template #activator="{ on, value }">
              <v-btn
                color="primary lighten-2"
                small depressed
                class="py-4 mr-2"
                v-on="on"
                v-if="true"
                @click="!$refs.otherAccounts || value ? null:$refs.otherAccounts.loadAccounts()"
              >
                <v-icon left>mdi-account-supervisor-circle</v-icon>
                Contas
              </v-btn>
            </template>
          </v-menu>
          
          <v-btn
            color="primary lighten-2"
            small depressed
            class="py-4 mr-2"
            :to="{ name: 'users' }"
            v-if="user.is_admin"
          >
            <v-icon left>mdi-account-supervisor-outline</v-icon>
            Usuários
          </v-btn>
          <v-btn
            color="primary lighten-2"
            small depressed
            class="py-4 mr-2"
            :to="{ name: 'settings' }"
          >
            <v-icon left>mdi-cog-outline</v-icon>
            Configurações
          </v-btn>
          <v-btn
            color="primary lighten-2"
            small depressed
            class="py-4"
            @click="logout()"
          >
            <v-icon left>mdi-logout-variant</v-icon>
            Sair
          </v-btn>
        </template>
      </div>

      <v-spacer />
    </v-app-bar>

    <v-navigation-drawer v-model="menu" fixed temporary>
      <Menu logo />
    </v-navigation-drawer>

    <v-main>
      <router-view />
    </v-main>

    <v-snackbar
      v-model="messageSnackbar.showing"
      :color="messageSnackbar.color"
      :timeout="3000"
      min-width="0"
    >
      <span
        class="font-weight-medium"
        v-html="messageSnackbar.message"
      />
      <template #action>
        <v-btn text @click="messageSnackbar.showing = false">
          ok
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="successDialog.showing" max-width="350px">
      <v-card color="white text-center" class="py-6">
        <v-img src="/icons/econsenso-natural.png" height="80px" contain />

        <v-card-title
          class="primary--text font-weight-bold pt-4 pb-2 d-block"
          style="word-break: normal"
        >
          {{ successDialog.title }}
        </v-card-title>
        <v-card-text class="font-weight-medium primary--text text--lighten-1">
          {{ successDialog.message }}
        </v-card-text>
        <v-card-actions class="d-flex justify-center pt-1">
          <v-btn
            color="primary"
            outlined
            class="px-8"
            @click="successDialog.showing = false"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <CookiesWarning />
  </v-app>
</template>

<script>
import Vue from "vue";
import Menu from "@/components/Menu.vue";
import OtherAccounts from "@/components/OtherAccounts.vue";
import CookiesWarning from "@/components/CookiesWarning.vue";

export default {
  name: "App",

  components: { Menu, OtherAccounts, CookiesWarning },
  data: () => ({
    menu: false,
    messageSnackbar: {
      showing: false,
      message: "",
      color: "",
    },
    successDialog: {
      showing: false,
      title: "",
      message: "",
    },
  }),


  computed: {
    user(){
      return this.$store.state.user;
    },
  },

  methods: {
    showMessage(color, msg) {
      this.messageSnackbar.showing = false;
      this.$nextTick(() => {
        this.messageSnackbar.color = color;
        this.messageSnackbar.message = msg;
        this.messageSnackbar.showing = true;
      });
    },
    showSuccessMessage(title, msg) {
      this.messageSnackbar.showing = false;
      this.$nextTick(() => {
        this.successDialog.title = title;
        this.successDialog.message = msg;
        this.successDialog.showing = true;
      });
    },
    logout() {
      this.$store.commit('logout');
      this.$router.push({ name: "login" });
    },
  },

  created() {
    Vue.prototype.$showMessage = this.showMessage;
    Vue.prototype.$showSuccessMessage = this.showSuccessMessage;
  },
};
</script>

<style>
.v-application,
.v-application [class*="text-"] {
  font-family: "Montserrat" !important;
}
</style>
