<template>
  <div>
    <v-expand-transition>
      <v-progress-linear v-if="loading" color="info" indeterminate />
    </v-expand-transition>
    <v-list dense class="rounded-b-sm">
      <v-list-item v-if="error">
        <v-list-item-subtitle class="error--text">
          Erro ao carregar outras contas
        </v-list-item-subtitle>
      </v-list-item>
      <template v-for="account in accounts">
        <v-list-item :key="account.id" @click="changeToAccount(account.id)">
          <v-list-item-subtitle class="font-weight-medium">
            {{ account.name }}
          </v-list-item-subtitle>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "OtherAccounts",

  data: () => ({
    loading: false,
    error: false,
    accounts: [],
  }),

  methods: {
    loadAccounts(){
      this.accounts = [];
      this.loading = true;
      this.error = false;
      this.$axios.get('/linked-accounts')
        .then((response) => {
          this.accounts = response.data;
        })
        .catch(() => {
          this.error = true;
        })
        .then(() => {
          this.loading = false;
        })
    },
    changeToAccount(account_id){
      this.loading = true;
      this.$axios.patch(`/access-account/${account_id}`)
        .then((response) => {
          this.$store.commit("setUser", response.data);
          this.$nextTick(() => {
            this.$router.go();
          });
        })
        .catch(() => {
          this.$showMessage('error', "Erro ao trocar conta");
        })
        .then(() => {
          this.loading = false;
        })
    },
  },

  mounted(){
    this.loadAccounts();
  },

}
</script>
