import Vue from 'vue'
import Vuex from 'vuex'
import { getCookie, removeCookie } from 'tiny-cookie'

let token = getCookie('token')
let user = {}
let logged = false

if (token){
  try {
    user = JSON.parse(atob(token.split('.')[1]))
    logged = true
  } catch (error){
    console.log(error);
  }
}

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    logged,
    user,
  },
  mutations: {
    setUser(state, user){
      state.user = user
      state.logged = true
    },
    logout(state){
      state.user = {}
      state.logged = false
      removeCookie('token', {domain: '.econsenso.com.br'})
    },
  },
  actions: {
  },
  modules: {
  }
})
